// import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import { AppName } from '@constant';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const APP = [
  {
    name: 'repairy',
    path: './App'
  },
  {
    name: 'flytree',
    path: './Flytree'
  },
];

// Determine which entry point to import
const { path } = APP.find(({ name }) => AppName === name);

// Import the entry point and render it's default export
import(`${path}`).then(({ default: Application }) =>
  ReactDOM.render(
    <React.StrictMode>
      <Application />
    </React.StrictMode>,
    document.getElementById("root")
  )
);
